<template>
    <div>
        <form novalidate class="md-layout" @submit.prevent="validateUser">
        <md-card class="md-layout-item md-size-50 md-small-size-100">
            <md-card-header>
            <div class="md-title">New Dispatcher</div>
            </md-card-header>

            <md-card-content>
                <div class="md-layout md-gutter">
                    <div class="md-layout-item">
                        <md-field :class="getValidationClass('email')">
                            <label for="email">Email</label>
                            <md-input type="email" name="email" id="email" autocomplete="email" v-model="email" :disabled="sending" />
                            <span class="md-error" v-if="!$v.email.required">The email is required</span>
                            <span class="md-error" v-else-if="!$v.email.email">Invalid email</span>
                        </md-field>
                        <md-field :class="getValidationClass('displayName')">
                            <label for="display-name">Display Name</label>
                            <md-input name="display-name" id="display-name" autocomplete="given-name" v-model="displayName" :disabled="sending" />
                            <span class="md-error" v-if="!$v.displayName.required">The first name is required</span>
                            <span class="md-error" v-else-if="!$v.displayName.minlength">Invalid first name</span>
                        </md-field>
                        <md-field :class="getValidationClass('password')">
                            <label for="password">Password</label>
                            <md-input type="password" name="password" id="password" autocomplete="given-name" v-model="password" :disabled="sending" />
                            <span class="md-error" v-if="!$v.password.required">Password is required</span>
                        </md-field>
                        
                    </div>
                
                
                </div>
            
            </md-card-content>

            <md-progress-bar md-mode="indeterminate" v-if="sending" />

            <md-card-actions>
            <md-button type="submit" class="md-primary" :disabled="sending">Create dispatcher</md-button>
            </md-card-actions>
        </md-card>

        <md-snackbar :md-active.sync="userSaved">The user {{ lastUser }} was saved with success!</md-snackbar>
        </form>
    </div>
</template>

<script>


import { validationMixin } from 'vuelidate'
  import {
    required,
    email,
    minLength,
    maxLength
  } from 'vuelidate/lib/validators'

import axios from "./../../helpers/axios"

export default {
  name: 'NewDispatcher',
  mixins: [validationMixin],
  data() {
    return {
      email: '',
      password: '',
      displayName: '',
      role: '',
      userSaved: false,
      sending: false,
      lastUser: null,
      
    }
  },
  methods: {
    getValidationClass (fieldName) {
        const field = this.$v[fieldName]

        if (field) {
            return {
                'md-invalid': field.$invalid && field.$dirty
            }
        }
    },
    clearForm () {
        this.$v.$reset()
        this.email = null
        this.password = null
        this.displayName = null
        this.role = null
        
    },
    saveUser () {
        this.sending = true
        this.registerButtonPressed()
        // Instead of this timeout, here you can call your API
        // window.setTimeout(() => {
        //     this.lastUser = `${this.form.firstName} ${this.form.lastName}`
        //     this.userSaved = true
        //     this.sending = false
        //     this.clearForm()
        // }, 1500)
    },
    validateUser () {
        this.$v.$touch()

        if (!this.$v.$invalid) {
            this.saveUser()
        }
    },
    async registerButtonPressed() {
      try {
          ////
        let th = this ;
        let data = {
            displayName: this.displayName, 
            password: this.password, 
            email: this.email, 
            ownerEmail: this.$store.getters.user.data.email
        }

       
        this.$firebase.auth().currentUser.getIdToken(true).then(function(idToken) {


            axios.post('dispatchers',data).then(response =>{
                console.log(response);
                th.sending = false
                th.$router.push({path: '/users/drivers'})
            }).catch(e => {
                console.log(e);
                th.sending = false
            }) 

        
        }).catch(function(error) {
        //Handle error
        });
          ////
       
      } catch (error) {
        console.log(error.message);
      }
      
    }

  },
  validations: {
    email: {
        required,
        email
    },
    password: {
        required,
        minLength: minLength(3)
    },
    displayName: {
        required,
        minLength: minLength(3)
    },
    
  },
}
</script>